import { Toaster as Sonner, toast as showToast, ToasterProps } from "sonner";
import { useEffect } from "react";
import { type Toast } from "~/lib/toast.server";
import {
  CheckCircledIcon,
  InfoCircledIcon,
  ExclamationTriangleIcon,
  QuestionMarkCircledIcon,
} from "@radix-ui/react-icons";
import { Badge, Spinner } from "@radix-ui/themes";

type DisallowedProps = keyof Pick<ToasterProps, "icons" | "toastOptions">;

export function Toaster(props: Omit<ToasterProps, DisallowedProps>) {
  return (
    <Sonner
      icons={{
        success: (
          <Badge
            color="green"
            radius="full"
            className="w-[28px] h-[28px] flex items-center justify-center"
          >
            <CheckCircledIcon width={24} height={24} />
          </Badge>
        ),
        info: (
          <Badge
            color="blue"
            radius="full"
            className="w-[28px] h-[28px] flex items-center justify-center"
          >
            <InfoCircledIcon width={24} height={24} />
          </Badge>
        ),
        warning: (
          <Badge
            color="yellow"
            radius="full"
            className="w-[28px] h-[28px] flex items-center justify-center"
          >
            <QuestionMarkCircledIcon width={24} height={24} />
          </Badge>
        ),
        error: (
          <Badge
            variant="outline"
            color="red"
            radius="full"
            className="w-[28px] h-[28px] flex items-center justify-center border-none shadow-none"
          >
            <ExclamationTriangleIcon width={24} height={24} />
          </Badge>
        ),
        loading: (
          <Badge
            variant="outline"
            color="gray"
            radius="full"
            className="w-[28px] h-[28px] flex items-center justify-center border-none shadow-none"
          >
            <Spinner />
          </Badge>
        ),
      }}
      toastOptions={{
        className: "[&[data-styled=true][data-styled=true]>[data-icon]]:w-auto",
        style: {
          background: "var(--color-panel-solid)",
        },
      }}
      {...props}
    />
  );
}

export function useToast(toast?: Toast | null) {
  useEffect(() => {
    if (!toast) return;

    setTimeout(() => {
      showToast[toast.type](toast.title, {
        id: toast.id,
        description: toast.description,
      });
    }, 0);
  }, [toast]);
}
